*{
    margin: 0;
    padding: 0;
    
font-size: 0;

    
    
}

.container{
    display: inline-block;
    justify-content: center;
    width: fit-content;
    align-items: center;
}

.tiles-full{
    height: 44vh;
    width: 40vh;
    border-radius: 7px;
    
    margin-top: 4px;
   border: 0.5px solid rgb(201, 190, 190);
   display: block;
   align-items: center;
    
    
}
.tiles-bottom{
    display: flex;
    margin: 10px;
    margin-bottom: 7px;
    

}
.icon-text{
    display: grid;
    
    padding:3px 8px;
    margin:2px ;
}

.featured-container{
    display: inline-block;
    justify-content: center;
    align-items: center;
    display: grid;
}
.featured-image{
    border: 0.5px solid rgb(201, 190, 190);
    border-radius: 25px;
}

    

.add-deduct{
    display: flex;
    
    border: 1px solid black;
    border-radius: 8px;
    margin-left: 244px;
    margin-right: 85px;
    padding: 1px 5px 1px 5px;
    position:relative;
}

.beverage-container{
    display: inline-flex;
    justify-content: center;
    align-items:center;
    border: 0.5px solid rgb(213, 196, 196);
    border-radius: 12px;
    text-align: center;
    padding: 11px 16px 23px 17px;
    
    margin-right: 2px;
    height: 24vh;
    margin-bottom: '40px';
   overflow: hidden;
   max-width: 600px;
   min-width: 438px;
}
.beverage{
    justify-content: center;
    align-items:center;
    
}
    

@media (max-width: 550px) {
    .beverage-container{
        font-size: smaller;
    }
}
@media (max-width: 500px) {
    .beverage-image img{
      font-size: 6px; /* Reduce font size for smaller screens */
      margin: 0px 9px -46px 100px;
      height: 12vh;
      width: 12vh; /* Adjust padding for smaller screens */
    }
  }
.beverage-tile{
    flex-grow: 1;
    display:flex;
    flex-direction: column;
    margin-top: 22px;
    
   
   
}

    

.beverage-image img {
   height: 16vh;
   width: 16vh;
border-radius: 37px;
   padding: 30px;
   margin: 0px 9px -46px 164px;
   
  }
  .price{
     margin-top: 70px;
     margin-left:-40px;
     margin-bottom: 40px;
  }
 .browser-container{
    margin-top: 40px;
 }
 .bottomNav-container{
     
    margin-top: 25px;
    margin-bottom: 45px;
    justify-content: center;
    align-items: center;
    
   
}
.bottomNav-tile{
    
    width: 100%; /* Take the full width of its container */
    display: flex; /* Use flexbox layout */
    flex-direction: row; /* Arrange children in a row */
    justify-content: space-between; 
  color:grey;
    background-color: #f3efef;
}
.bottomNav-tile div:hover{
    color: rgb(6, 6, 6);
    transform: scale(1.1);
    fill: black; /* Default color */
    transition: fill 0.3s ease; 
    
   
}
.bottomNav-tile img:hover{
    fill:black;
}