.table-container{
    border: 2px solid black;
   display: inline-block;
   
}
@media (max-width: 500px) {
    .table-container input {
       max-width:50% ;
    }
  }
 

.table-container th{
    font-size: 18px;
    
    border: 1px solid black;
    padding:2px
}
.table-container td{
    font-size: 16px;
    text-align: start;
    border: 1px solid black;
    padding:2px
}
.table-container input{
    border-radius: 16px;
    padding-left: 10px;
    max-width: 12vh;
}
